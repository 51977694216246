* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  scroll-behavior: smooth;
}

a {
  color: inherit;
  text-decoration: none;
}
